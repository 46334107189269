<template>
  <b-row>
    <b-col cols="12">
      <b-table
        striped
        hover
        responsive
        :per-page="perPage"
        :current-page="currentPage"
        :items="items"
        :fields="fields"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        :filter="filter"
        :filter-included-fields="filterOn"
        @filtered="onFiltered"
      >
        <template #cell(date_hour)="data">
          <div class="text-nowrap">
            <span class="align-text-top">{{
              format(new Date(data.item.date_hour), "dd/MM/yyyy HH:mm:ss")
            }}</span>
          </div>
        </template>

        <template #cell(value)="data">
          <div class="text-nowrap">
            <span class="align-text-top">{{
              moneyFormat(data.item.value.split(" ~ ")[0])
            }}</span>
          </div>
        </template>

        <template #cell(price)="data">
          <div class="text-nowrap">
            <span class="align-text-top">{{
              moneyFormatFiat(data.item.price)
            }}</span>
          </div>
        </template>

        <template #cell(buy_sell)="data">
          <div class="text-nowrap">
            <span class="align-text-top">{{ $t(data.item.buy_sell) }}</span>
          </div>
        </template>

        <template #cell(result)="data">
          <div class="text-nowrap">
            <span
              :class="`align-text-top ${
                parseFloat(data.item.result.split(' ~ ')[0]) < 0
                  ? 'text-danger'
                  : parseFloat(data.item.result.split(' ~ ')[0]) > 0 &&
                    'text-success'
              }`"
              >{{ moneyFormat(data.item.result.split(" ~ ")[0]) }}</span
            >
          </div>
        </template>

        <template #cell(type)="data">
          <div class="text-nowrap">
            <span class="align-text-top">{{ $t(data.item.type) }}</span>
          </div>
        </template>
      </b-table>
    </b-col>

    <b-col cols="12">
      <b-pagination
        v-model="currentPage"
        :total-rows="totalRows"
        :per-page="perPage"
        align="center"
        size="sm"
        class="my-0"
      />
    </b-col>
  </b-row>
</template>

<script>
import {
  BTable,
  BAvatar,
  BBadge,
  BRow,
  BCol,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
} from "bootstrap-vue";
import { format } from "date-fns";

export default {
  components: {
    BTable,
    BAvatar,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      perPage: 10,
      pageOptions: [5, 10, 25, 100],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },
      fields: [
        {
          key: "date_hour",
          label: this.$i18n.t("Data / Hora"),
        },
        { key: "asset", label: this.$i18n.t("Ativo") },
        { key: "buy_sell", label: this.$i18n.t("Ação") },
        { key: "value", label: this.$i18n.t("Valor") },
        { key: "price", label: this.$i18n.t("Preço") },
        { key: "type", label: this.$i18n.t("Tipo") },
        { key: "result", label: this.$i18n.t("Resultado") },
      ],
      items: [],
    };
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length;
  },
  watch: {
    data: function (newVal) {
      this.items = newVal.items;
      this.totalRows = newVal.items.length;
    },
  },
  methods: {
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`;
      this.infoModal.content = JSON.stringify(item, null, 2);
      this.$root.$emit("bv::show::modal", this.infoModal.id, button);
    },
    resetInfoModal() {
      this.infoModal.title = "";
      this.infoModal.content = "";
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },      
  },
  setup() {
    format;

    return {
      format,
    };
  },
};
</script>