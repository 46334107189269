<template>
  <b-card v-if="data">
    <b-card no-body class="card-revenue-budget">
      <b-row class="mx-0">
        <b-col md="12">
          <div
            class="d-sm-flex justify-content-between align-items-center mb-3"
          >
            <h4 class="card-title mb-50 mb-sm-0">{{ $t("Balanço") }}</h4>
            <div class="d-flex align-items-center">
              <div class="d-flex align-items-center mr-2">
                <span
                  class="
                    bullet bullet-primary
                    svg-font-small-3
                    mr-50
                    cursor-pointer
                  "
                />
                <span>{{ $t("Lucro") }}</span>
              </div>
              <div class="d-flex align-items-center">
                <span
                  class="
                    bullet bullet-warning
                    svg-font-small-3
                    mr-50
                    cursor-pointer
                  "
                />
                <span>{{ $t("Prejuízo") }}</span>
              </div>
            </div>
          </div>

          <!-- chart -->
          <vue-apex-charts
            id="revenue-report-chart"
            type="bar"
            height="230"
            :options="reportProfitXLoss.chartOptions"
            :series="reportProfitXLoss.series"
          />
        </b-col>
      </b-row>
    </b-card>
    <hr />
    <!-- progress bar -->
    <b-row class="avg-sessions pt-50">
      <b-col cols="6">
        <b-card-text class="mb-50">
          {{ $t("Prejuízo") }}:
          {{
            moneyFormat(data.loss)
          }}
        </b-card-text>
        <b-progress
          :value="parseInt((data.loss / data.volume) * 100)"
          max="100"
          height="6px"
          variant="danger"
          class="mt-25"
        />
      </b-col>
      <b-col cols="6">
        <b-card-text class="mb-50">
          {{ $t("Lucro") }}:
          {{
            moneyFormat(data.profit)
          }}
        </b-card-text>
        <b-progress
          :value="parseInt((data.profit / data.volume) * 100)"
          max="100"
          variant="success"
          height="6px"
          class="mt-25"
        />
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BButton,
  BDropdown,
  BDropdownItem,
  BProgress,
  BCardText,
} from "bootstrap-vue";
import VueApexCharts from "vue-apexcharts";
import Ripple from "vue-ripple-directive";
import { $themeColors } from "@themeConfig";
import { kFormatter } from "@core/utils/filter";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BDropdown,
    BDropdownItem,
    BProgress,
    BCardText,
    VueApexCharts,
  },
  directives: {
    Ripple,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      reportProfitXLoss: {
        series: [],
        chartOptions: {
          chart: {
            stacked: true,
            type: "bar",
            toolbar: { show: false },
          },
          grid: {
            padding: {
              top: -20,
              bottom: -10,
            },
            yaxis: {
              lines: { show: false },
            },
          },
          xaxis: {
            categories: [],
            labels: {
              style: {
                colors: "#b9b9c3",
                fontSize: "0.86rem",
              },
            },
            axisTicks: {
              show: false,
            },
            axisBorder: {
              show: false,
            },
          },
          legend: {
            show: false,
          },
          dataLabels: {
            enabled: false,
          },
          colors: [$themeColors.primary, $themeColors.warning],
          plotOptions: {
            bar: {
              columnWidth: "17%",
              endingShape: "rounded",
            },
            distributed: true,
          },
          yaxis: {
            labels: {
              style: {
                colors: "#b9b9c3",
                fontSize: "0.86rem",
              },
            },
          },
        },
      },
      salesBar: {
        chartOptions: {
          chart: {
            sparkline: { enabled: true },
            toolbar: { show: false },
          },
          grid: {
            show: false,
            padding: {
              left: 0,
              right: 0,
            },
          },
          states: {
            hover: {
              filter: "none",
            },
          },
          colors: [
            "#ebf0f7",
            "#ebf0f7",
            $themeColors.primary,
            "#ebf0f7",
            "#ebf0f7",
            "#ebf0f7",
          ],
          plotOptions: {
            bar: {
              columnWidth: "45%",
              distributed: true,
              endingShape: "rounded",
            },
          },
          tooltip: {
            x: { show: false },
          },
          xaxis: {
            type: "numeric",
          },
        },
      },
    };
  },
  watch: {
    data: function (newVal) {
      this.reportProfitXLoss.series = newVal.series;
      this.reportProfitXLoss.chartOptions.xaxis.categories = newVal.xaxis.categories;
    },
  },
  methods: {
    kFormatter,
  },
};
</script>
