<template>
  <div>
    <vue-slider v-model="allocationValue" :max="parseInt(balance)" />
    <b-form-input id="allocation-value" v-model="allocationValue" name="allocation-value" placeholder="$0.00" class="mt-1"
      type="number" />
    <div class="mt-1">Valor que será alocado: {{ moneyFormat(allocationValue) }}</div>
  </div>
</template>

<script>
import VueSlider from "vue-slider-component";

export default {
  components: {
    VueSlider,
  },
  props: {
    balance: Number,
  },
  data() {
    return {
      allocationValue: 0,
    };
  },
  watch: {
    allocationValue: function (newVal) {
      let value = newVal;
      this.$emit("allocationValue", value);
    },
  },
};
</script>