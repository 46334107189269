<template>
  <b-card no-body v-if="dataChart">
    <b-card-header>
      <!-- title and subtitle -->
      <div>
        <b-card-title class="mb-1">
          {{ $t("Evolução do patrimônio") }}
        </b-card-title>
        <b-card-sub-title>{{
          $t("Período: ") + dataChart.startDate + " - " + dataChart.endDate
        }}</b-card-sub-title>
      </div>
      <!--/ title and subtitle -->

      <!-- badge -->
      <div class="d-flex align-items-center flex-wrap mt-sm-0 mt-1">
        <div class="mr-2">
          <h5 class="font-weight-bolder mb-0 mr-1">
            {{
              moneyFormat(dataChart.initialEquity)
            }}
          </h5>
          <small>{{ $t("Patrimônio inicial") }}</small>
        </div>
        <div class="mr-2">
          <h5 class="font-weight-bolder mb-0 mr-1">
            {{
              moneyFormat(dataChart.currentEquity)
            }}
          </h5>
          <small>{{ $t("Patrimônio atual") }}</small>
        </div>
        <b-badge variant="light-secondary">
          <feather-icon
            :icon="
              (dataChart.currentEquity / dataChart.initialEquity) * 100 - 100 >
              0
                ? 'ArrowUpIcon'
                : 'ArrowDownIcon'
            "
            size="16"
            :class="
              (dataChart.currentEquity / dataChart.initialEquity) * 100 - 100 >
              0
                ? 'text-success mr-25'
                : 'text-danger mr-25'
            "
          />
          <span class="align-middle"
            >{{
              (
                (dataChart.currentEquity / dataChart.initialEquity) * 100 -
                100
              ).toFixed(2)
            }}%</span
          >
        </b-badge>
      </div>
      <!--/ badge -->
    </b-card-header>
    <b-card-body>
      <vue-apex-charts
        type="line"
        height="400"
        :options="lineChartSimple.chartOptions"
        :series="lineChartSimple.series"
      />
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardBody,
  BCardHeader,
  BCardTitle,
  BCardSubTitle,
  BBadge,
} from "bootstrap-vue";
import VueApexCharts from "vue-apexcharts";
import { $themeColors } from "@themeConfig";

export default {
  components: {
    VueApexCharts,
    BCardHeader,
    BCard,
    BBadge,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
  },
  props: {
    dataChart: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      lineChartSimple: {
        series: [],
        chartOptions: {
          chart: {
            zoom: {
              enabled: false,
            },
            toolbar: {
              show: false,
            },
          },
          markers: {
            strokeWidth: 7,
            strokeOpacity: 1,
            strokeColors: [$themeColors.light],
            colors: [$themeColors.warning],
          },
          colors: [$themeColors.warning],
          dataLabels: {
            enabled: false,
          },
          stroke: {
            curve: "straight",
          },
          grid: {
            xaxis: {
              lines: {
                show: true,
              },
            },
          },
          tooltip: {
            custom(data) {
              return `${'<div class="px-1 py-50"><span>'}${Intl.NumberFormat(
                "en-US",
                {
                  style: "currency",
                  currency: "USD",
                }
              ).format(
                data.series[data.seriesIndex][data.dataPointIndex]
              )}</span></div>`;
            },
          },
          xaxis: {},
          yaxis: {
            decimalsInFloat: 2,
          },
        },
      },
    };
  },
  watch: {
    dataChart: function (newVal) {
      this.lineChartSimple.series = newVal.series;
      this.lineChartSimple.chartOptions.xaxis = newVal.xaxis;
    },
  },
};
</script>
