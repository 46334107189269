<template>
  <div>
    <!-- basic modal -->
    <b-modal id="modal-copy" :title="$i18n.t('Copiar robô trader')" ok-only
      :ok-title="loading ? $i18n.t('Aguarde...') : $i18n.t('Confirmar')" @ok="handleOk" centered :ok-disabled="loading">
      <div>
        {{
          $t(
            "Quanto do seu capital disponível você gostaria de investir?"
          )
        }}
      </div>
      <slider :balance="returnUser.balance" class="my-1" @allocationValue="(val) => (allocationValue = val)" />
      <b-alert variant="warning" :show="!hideMsg">
        <div class="alert-body">
          <span class="text-warning">{{
            $t(
              `Atenção: o valor alocado está sujeito a oscilações conforme as operações realizadas. Confira seus
            resultados na opção "Meus resultados" do menu.`
            )
          }}</span>
        </div>
      </b-alert>
      <alert :text="textAlert" :color="colorAlert" :showAlert="showAlert"
        @changeVariableAlert="(val) => (showAlert = val)" />
    </b-modal>
  </div>
</template>

<script>
import { BModal, BButton, VBModal, BAlert } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import Slider from "./Slider.vue";
import { mapGetters } from "vuex";

export default {
  components: {
    BButton,
    BModal,
    BAlert,
    Slider,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  props: {
    openModal: Boolean,
    hideMsg: Boolean,
  },
  computed: mapGetters(["returnUser"]),
  watch: {
    openModal: function (newVal) {
      if (newVal == true) {
        this.$bvModal.show("modal-copy");
      }
      this.$emit("changeVariable");
    },
  },
  data() {
    return {
      allocationValue: 100,
      showAlert: false,
      textAlert: "",
      colorAlert: "warning",
      loading: false
    };
  },
  methods: {
    handleOk(bvModalEvt) {
      bvModalEvt.preventDefault();

      this.handleSubmit();
    },
    handleSubmit() {
      this.loading = true
      this.$store
        .dispatch("allocateValueInTrading", this.allocationValue)
        .then((resp) => {
          this.showAlert = true;
          this.colorAlert = "success";
          this.textAlert = this.$i18n.t("Novo copy trade criado com sucesso!");
          this.$store.dispatch("changeBalance", -this.allocationValue);
          this.$emit("reloadNumberBots");
          setTimeout(() => { this.$bvModal.hide("modal-copy"); this.loading = false }, 2000);
        })
        .catch((error) => {
          this.loading = false
          this.showAlert = true;
          this.colorAlert = "danger";
          this.textAlert = error.message;
        });
    },
  },
};
</script>

<style lang="scss" >
@import "@core/scss/vue/libs/vue-slider.scss";
</style>