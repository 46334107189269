<template>
  <b-card v-if="data" text-variant="center" class="card">
    <b-img
      fluid
      :src="imgUrl"
      alt="Login V2"
      style="max-height: 5rem"
      class="mb-1"
    />
    <h1 class="mb-1 mt-50">
      <strong>SatoBot</strong>
    </h1>
    <b-card-text class="m-auto w-75">
      {{ $t("Este robô está com uma taxa média de acerto de ") }}
      <strong>{{ data.hitRate }}%</strong> {{ $t("no par") }}
      <strong>{{ data.currencyPair }}</strong
      >. {{ $t("Em") }} <strong>{{ data.operatingDays + $t(" dias") }}</strong>
      {{ $t("de operações, retornou") }}
      <strong>{{ data.profitReturn }}%</strong> {{ $t("de rendimento + bônus") }}.
    </b-card-text>
  </b-card>
</template>

<script>
import { BCard, BImg, BAvatar, BCardText } from "bootstrap-vue";

export default {
  components: {
    BCard,
    BAvatar,
    BImg,
    BCardText,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    imgUrl() {
      return require("@/assets/images/logo/newxcoins_favicon_light.png");
    },
  },
};
</script>
