<template>
  <section id="dashboard-analytics">
    <b-row class="match-height">
      <b-col md="6">
        <analytics-summary :data="data.summary" />
      </b-col>
      <b-col md="3">
        <statistic-card-with-area-chart v-if="data.netWorthGained" icon="DollarSignIcon" color="success"
          :statistic="moneyFormat(data.netWorthGained.totalValue)" :statistic-title="$t('Rendimento de trade')"
          :chart-data="data.netWorthGained.series" />
      </b-col>
      <b-col md="3">
        <statistic-card-with-area-chart v-if="data.patrimony" icon="DollarSignIcon" color="warning"
          :statistic="moneyFormat(data.patrimony.totalValue)" :statistic-title="$t('Bônus de indicação')"
          :chart-data="data.patrimony.series" />
      </b-col>
    </b-row>

    <b-row>
      <b-col cols="12">
        <apex-line-chart :dataChart="data.lineChartSimple" />
      </b-col>
    </b-row>

    <b-row class="match-height">
      <b-col lg="6">
        <report-profit-x-loss :data="data.reportProfitXLoss" />
      </b-col>
      <b-col lg="6">
        <analytics-global-vision :data="data.globalVision" />
      </b-col>
    </b-row>

    <b-row>
      <b-col cols="12">
        <b-card>
          <orders-list :data="data.ordersList" />
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import { BRow, BCol } from "bootstrap-vue";

import StatisticCardWithAreaChart from "@core/components/statistics-cards/StatisticCardWithAreaChart.vue";
import { kFormatter } from "@core/utils/filter";
import AnalyticsSummary from "./AnalyticsSummary.vue";
import ReportProfitXLoss from "./ReportProfitXLoss.vue";
import AnalyticsGlobalVision from "./AnalyticsGlobalVision.vue";
import ApexLineChart from "./ApexLineChart.vue";
import OrdersList from "./OrdersList.vue";
import CopyTrades from "./copy-trades/CopyTrades.vue";

import { BAlert } from "bootstrap-vue";

export default {
  components: {
    BRow,
    BCol,
    AnalyticsSummary,
    ReportProfitXLoss,
    StatisticCardWithAreaChart,
    AnalyticsGlobalVision,
    ApexLineChart,
    OrdersList,
    CopyTrades,
    BAlert,
  },
  data() {
    return {
      data: {},
      openModal: false,
      myRobots: null,
      valueAllocatedRobots: 0,
      profitRobots: 0,
      loading: true,
      arrayRobots: [],
      robot_id: ''
    };
  },
  mounted() {
    // data
    this.myRobotsCopyTrade();
  },
  methods: {
    kFormatter,
    myRobotsCopyTrade() {
      this.$store.dispatch("dashboardMyResults").then((response) => {
        response.arrayRobots.map(e => {
          this.arrayRobots.push({ id: e._id, name: e.name })
          if (this.robot_id == '')
            this.robot_id = e._id
        })
        this.myRobots = parseInt(response.botsRunning);
        this.valueAllocatedRobots = response.initialValue;
        this.profitRobots = response.totalProfit;
        setTimeout(() => {
          this.getRobotDashboard(this.robot_id);
          this.loading = false;
        }, 2000);
      });
    },
    getRobotDashboard(robot_id) {
      this.$store.dispatch("dashboardAnalytics", robot_id).then((response) => {
        this.data = response.data;
      });
    }
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "@core/scss/vue/libs/chart-apex.scss";
</style>
